import React, { ReactElement } from 'react'
import './MainHeader.scss';

const MainHeader = (): ReactElement => {
  return (
    <div className="main-header">
      <div className="main-header__heading">Hello User</div>
      <div className="main-header__updates">Recent Items</div>
    </div>
  )
}

export default MainHeader
