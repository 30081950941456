import React, { ReactElement } from 'react';

import { Footer, Header, Main, Sidebar } from './components';

import './Dashboard.scss';

const Dashboard = (): ReactElement => {
  return (
    <div className="grid-container">
      <header className="header">
        <Header />
      </header>

      <aside className="sidebar">
        <Sidebar />
      </aside>

      <main className="main">
        <Main />
      </main>

      <footer className="footer">
        <Footer />
      </footer>
    </div>
  )
}

export default Dashboard;
