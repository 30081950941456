import React, { ReactElement } from 'react';
import './Form.scss'

import SignUpButton from './components/SignUpButton';
import LogInButton from './components/LogInButton';

export const Form = (): ReactElement => {
  return (
    <div className={'stu-login-form-container'}>
      <SignUpButton />
      <LogInButton />
    </div>
  )
}
