import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogOutButton = (): ReactElement => {
  const { logout } = useAuth0();

  return (
    <button
      style={{ backgroundColor: "red" }}
      onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
    </button>
  )
}

export default LogOutButton;
