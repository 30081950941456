import React, { ReactElement } from 'react';
import {
  Switch,
  Route
} from "react-router-dom";
import Dashboard from './pages/Dashboard/Dashboard';
import { Login } from './pages/Login/Login';
import ProtectedRoute from './shared/components/Auth/ProtectedRoute';
import Loading from './shared/components/Loading/Loading';
import { useAuth0 } from '@auth0/auth0-react';

import './styles/style.scss';

export default function App(): ReactElement {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />
  }

  return (
    <Switch>
      <Route path="/" exact component={Login} />
      <ProtectedRoute path="/dashboard" exact component={Dashboard} />
    </Switch>
  )
}
