import React, { ReactElement } from 'react';
import './MainOverview.scss';

const MainOverview = (): ReactElement => {
  return (
    <div className="main-overview">
      <div className="overviewcard">
        <div className="overviewcard__icon">Overview</div>
        <div className="overviewcard__info">Card</div>
      </div>
      <div className="overviewcard">
        <div className="overviewcard__icon">Overview</div>
        <div className="overviewcard__info">Card</div>
      </div>
      <div className="overviewcard">
        <div className="overviewcard__icon">Overview</div>
        <div className="overviewcard__info">Card</div>
      </div>
      <div className="overviewcard">
        <div className="overviewcard__icon">Overview</div>
        <div className="overviewcard__info">Card</div>
      </div>
    </div>
  )
}

export default MainOverview
