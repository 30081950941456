import React from 'react';
import './MainCards.scss';

const MainCards = () => {
  return (
    <div className="main-cards">
      <div className="card">Card</div>
      <div className="card">Card</div>
      <div className="card">Card</div>
    </div>
  )
}

export default MainCards
