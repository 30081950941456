import React, { ReactElement } from 'react'
import './Footer.scss';

const Footer = (): ReactElement => {
  return (
    <>
      <div className="footer__copyright">&copy; 2020 ORAX</div>
      <div className="footer__signature">Horacio Escoto Cardenas</div>
    </>
  )
}

export default Footer
