import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const SignUpButton = (): ReactElement => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className="stu-login-form-container__item--signup"
      onClick={() =>
        loginWithRedirect({
          screen_hint: "signup"
        })
      }
    >
      Sign Up
    </button>
  );
};

export default SignUpButton;