import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react'

const LogInButton = (): ReactElement => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className='stu-login-form-container__item--login'
      onClick={() => loginWithRedirect()}
    >
      Log In
    </button>
  );
};

export default LogInButton;