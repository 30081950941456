import React, { ReactElement } from 'react';
import { MainCards, MainHeader, MainOverview } from './components';
import './Main.scss';

const Main = (): ReactElement => {
  return (
    <>
      <MainHeader />
      <MainOverview />
      <MainCards />
    </>
  )
}

export default Main;