import { ReactElement } from "react";
import { Form } from "./components/Form/Form";
import { Picture } from "./components/Picture/Picture";

import './Login.scss';

export const Login = (): ReactElement => {
  return (
    <div className="stu-login-container">
      <div className="stu-login-container__item">
        <Picture />
      </div>
      <div className="stu-login-container__item">
        <Form />
      </div>
    </div>
  );
}
