import React, { ReactElement } from 'react';
import LogOutButton from 'src/shared/components/LogOutButton';
import './Header.scss';

const Header = (): ReactElement => {
  return (
    <>
      <div className="header__search">Search...</div>
      <div className="header__avatar">Your face</div>
      <LogOutButton />
    </>
  )
}

export default Header;
