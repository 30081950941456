import React, { ReactElement } from 'react';
import './Sidebar.scss';

const Sidebar = (): ReactElement => {
  return (
    <>
      <ul className="sidebar__list">
        <li className="sidebar__list-item">Generated Links</li>
        <li className="sidebar__list-item">Charts</li>
        <li className="sidebar__list-item">Item Three</li>
        <li className="sidebar__list-item">Item Four</li>
        <li className="sidebar__list-item">Item Five</li>
      </ul>
    </>
  )
}

export default Sidebar;
