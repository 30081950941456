import React, { ReactElement, ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

interface authProps {
  children: ReactNode;
}

const Auth0ProviderWithHistory = ({ children }: authProps): ReactElement => {
  const history = useHistory();

  const onRedirectCallback = (): void => {
    history.push("/dashboard");
  };

  return (
    <Auth0Provider
      domain={'orax-tech.eu.auth0.com'}
      clientId={'EQQRTxv2EbTHT0UHzMC7bRAJBp4v05wp'}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory;